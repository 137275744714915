@import "https://fonts.googleapis.com/css?family=Roboto+Mono:400,400i|Secular+One";
@import "https://fonts.googleapis.com/icon?family=Material+Icons";


/* Imports */

/* Variables */

:root {
    /* colors */
    --primary-color: #EEEEEE;
    --secondary-color: #424242;
    --accent-color: #D32F2F;
    /* fonts */
    --Roboto-Mono: 'Roboto Mono', sans-serif;
    --Secular-One: 'Secular One', serif;
}

/* Page */

html, body {
    width: 100%;
    height: 100%;

    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    position: relative; /* <-- overflow hidden doesn't work without this! :( */
}

body {
    margin: 0;
    background-color: var(--primary-color);
    font: 18px var(--Secular-One);
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
    cursor: default;
}

/* Generics */

.display--none { display: none }

.width--full { width: 100% }

.height--full { height: 100% }

.margin-top--4 { margin-top: 4px }

.margin-top--8 { margin-top: 8px }

.margin-top--12 { margin-top: 12px }

.margin-top--24 { margin-top: 24px }

.margin-bottom--8 { margin-bottom: 8px }

.margin-bottom--12 { margin-bottom: 12px }

.margin-bottom--16 { margin-bottom: 16px }

.margin-bottom--32 { margin-bottom: 32px }

.margin-bottom--48 { margin-bottom: 48px }

.margin-left--6 { margin-left: 6px }

.text--center { text-align: center }

.white-space--pre-wrap { white-space: pre-wrap }

.float--right { float: right }

.vertical-align--top { vertical-align: top }

/* Everything else ... */

h2 {
    margin: 0 0 12px 0;
    font: 24px var(--Roboto-Mono);
}

p { margin: 0}

a { color: inherit }

input {
    outline: 0;
}

select {
    -webkit-appearance: unset;
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    outline: none;

    color: inherit;
    font: inherit;
    cursor: pointer;
}

#container {
    overflow: hidden;
}

#container, #hymn-app, .app-page {
    width: 100%;
    height: 100%;
}

#hymn-app {
    overflow: hidden;
}

div, span {
    display: inline-block;
    box-sizing: border-box;
}

.app-page {
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 0 2px 16px rgba(0, 0, 0, 0.8);

    background-color: var(--primary-color);
    color: var(--secondary-color);
    text-align: center;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    transition: 0.6s cubic-bezier(0.0, 0.0, 0.2, 1);
}

.app-page.hidden {
    top: 110%;
    transition-timing-function: cubic-bezier(0.4, 0.0, 1, 1);
}

#search-page.app-page.hidden {
    top: 0;
    display: none;
}

.page-content {
    width: 100%;
    max-width: 664px;
    padding: 32px;
    box-sizing: border-box;
    text-align: left;
}

hr {
    width: 100%;
    height: 2px;
    margin: 0 0 12px 0;
    border: none;
    background-color: var(--secondary-color);
}

#container .hide { display: none; }

.clickable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.clickable:not(.disabled) {
    cursor: pointer;
    transition: 0.2s;
}

.clickable:not(.disabled):hover { opacity: 0.8 }

.clickable.disabled { opacity: 0.4 }

/* Headers */

.page-header {
    display: block;
}

.page-header, .page-bottom, .page-footer {
    width: 100%;
}

.page-header.hidden {
    display: none;
}

.title-bar, .subtitle-bar {
    width: 100%;
}

.subtitle-bar {
    display: block;
    height: 26px;
    margin-bottom: 18px;
    transition: 0.6s;
    overflow: hidden;
}

.subtitle-bar.hidden {
    height: 0px;
    margin-bottom: 0px;
}

/* Page Title */

.page-title {
    font: 48px var(--Secular-One);
}

.page-subtitle {
    font: 14px var(--Roboto-Mono);
}

.right-title {
    float: right;
}

.page-close, .theme-button {
    margin: 21px 0;
    cursor: pointer;
}

.icon-tray {
    height: 24px;
    text-align: right;
}

.icon-tray i { margin-left: 10px; }

.icon-tray.right-title { margin: 23px 0 }

.icon-tray-left {
    height: 24px;
    text-align: left;
}

.icon-tray-left i { margin-right: 10px; }

/* */

/* Home Page */

#home-page >.page-content {
    position: relative;
    margin-bottom: 56px;
}

.home-section {
    width: 100%;
    margin-bottom: 16px;
}

.home-section-title {
    font: italic 24px var(--Roboto-Mono);
    margin-bottom: 8px;
    cursor: pointer;
}

#hymnal-info, #account-button {
    font: 18px var(--Secular-One);
    cursor: pointer;
}

#account-button {
    float: right;
}

#fab {

    position: fixed;
    bottom: 32px;
    right: 32px;

    width: 56px;
    height: 56px;
    padding: 16px;
    border-radius: 28px;
    box-sizing: border-box;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.8);

    color: var(--primary-color);
    background-color: var(--secondary-color);

    cursor: pointer;
    transition: 0.4s;

}

#fab:hover {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.8);
}

.home-header {
    margin: 60px 0;
}

.home-header > .page-title {
    position: relative;
    margin-bottom: -12px;
}

.home-header > .page-title > svg {
    position: absolute;
    display: inline-block;
    top: -70px;
    left: calc((100% - 212px) / 2);
    width: 212px;
    height: 194px;
    content: '';
    opacity: 0.06;
}

.home-header > .page-title path {
    fill: var(--secondary-color);
}

.home-header > .page-subtitle {
    position: relative;
}

.named-icon-button {
    width: 100px;
    margin: 0 calc((100% - 300px) / 8);
}

.named-icon-button > i { margin-bottom: 12px }

.named-icon-button > span { width: 100% }

.account-icon {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    border: 2px solid var(--secondary-color);
    overflow: hidden;
    vertical-align: top;
}

.account-icon > img {
    width: 100%;
    height: 100%;
}

.version-tag {
    height: 24px;
    font-size: 14px;
    vertical-align: top;
    margin: 3px 0 1px 0;
    opacity: 0.4;
}

/* Hymn List */

.hymn-list {
    width: 100%;
}

/* Hymn Entry */

.hymn-entry {
    width: 100%;
    font: 18px var(--Secular-One);
    line-height: 40px;
    cursor: pointer;
}

.hymn-entry-number {
    display: inline-block;
    width: 32px;
    margin-right: 12px;
    text-align: right;
    vertical-align: top;
}

.hymn-entry-title {
    width: calc(100% - 76px);
}

.hymn-entry-favorite {
    margin-left: 8px;
    float: right;
}

.hymn-entry-favorite .material-icons {
    vertical-align: middle;
}

/* Favorites */

.favorite {
    color: var(--secondary-color);
    opacity: 0.2;
    cursor: pointer;
}

.favorite.favorite-color {
    color: var(--accent-color);
}

.favorite.active {
    color: var(--accent-color);
    opacity: 0.8;
}

/* List Page */

#list-sort {

    color: var(--secondary-color);

    font: 18px var(--Secular-One);
    text-align: right;

    float: right;

}

#list-sort-select {
    -webkit-appearance: unset;
    border: none;
    background-color: transparent;

    color: inherit;
    font: inherit;
}

/* Search Page */

#search-bar {
    width: calc(100% - 48px);
}

.search-bar-input {
    -webkit-appearance: unset;
    background-color: transparent;

    color: var(--secondary-color);
    font: italic 24px var(--Roboto-Mono);

    width: 100%;
    border: none;
    margin: 18px 0;
    padding: 0;

}

.search-bar-input::-webkit-input-placeholder { /* Chrome/Opera/Safari */  color: var(--secondary-color); opacity: 0.2;  }

.search-bar-input::-moz-placeholder { /* Firefox 19+ */  color: var(--secondary-color); opacity: 0.2;  }

.search-bar-input:-ms-input-placeholder { /* IE 10+ */  color: var(--secondary-color); opacity: 0.2;  }

.search-bar-input:-moz-placeholder { /* Firefox 18- */  color: var(--secondary-color); opacity: 0.2;  }

/* Hymn Page */

.hymn-number {
    width: 120px;
    font: 48px var(--Secular-One);
}

.hymn-number.long { width: 200px }

#hymn-name {
    width: 100%;
    font: 18px var(--Roboto-Mono);
}

.hymn-lyrics {
    white-space: pre-line;
    font: 18px var(--Secular-One);
    line-height: 40px;
}

.presentation-content {
    width: 100%;
    padding: 32px;
    box-sizing: border-box;
    text-align: left;
    height: 100%;
}

.presentation-content > .page-bottom { height: calc(100% - 96px); }

.presentation-content > .page-footer {
    height: 26px;
    text-align: left;
}

.flickity-viewport {
    display: block;
}

.flickity-button-icon { fill: var(--secondary-color) }

.main-carousel {
    width: calc(100% + 64px);
    margin-left: -32px;
}

.carousel-cell {
    padding: 0 32px;
}

/*.slide-title {*/

/*    width: 240px;*/

/*    font: 48px var(--Secular-One);*/

/*    margin-left: calc(50% - 120px);*/

/*    text-align: center;*/

/*}*/

.splash-title {
    width: 100%;
    font: 48px var(--Secular-One);
}

.splash-subtitle {
    width: 100%;
    font: 18px var(--Roboto-Mono);
}

.presentation-content > .page-footer {
    text-align: right;
}

.slide-char-view {
    font-size: 18px;
    width: 26px;
    height: 26px;
    text-align: center;
    opacity: 0.4;
    transition: 0.6s;
}

.slide-char-view.active {
    opacity: 1;
}

.flickity-button, .flickity-button:hover {
    background: transparent;
}

.flickity-prev-next-button {
    width: 24px;
    height: 24px;
}

.flickity-prev-next-button.previous {
    left: 8px;
}

.flickity-prev-next-button.next {
    right: 8px;
}

/* Account Page */

#signin-buttons {
    width: 100%;
    padding-top: 100px;
    text-align: center;
}

#account-page .page-content { height: 100% }

#account-page .page-bottom { height: calc(100% - 70px) }

.sign-in-message {
    font-size: 24px;
}

img.sign-in-provider-button {
    width: 198px;
    height: 42px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
    transition: 0.6s;
}

img.sign-in-provider-button:hover {
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.sign-in-provider-button-separator {
    margin: 8px 10px;
    vertical-align: top;
}

img.sign-in-provider-icon {
    width: 42px;
    height: 42px;
    margin-left: 10px;
    vertical-align: middle;
}

/* SNACKBAR */

.snackbar {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 14px 0;

    background-color:  var(--secondary-color);
    color: var(--primary-color);

    font: 14px var(--Secular-One);

    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);

    transition: 0.6s;
}

.snackbar > .left {
    padding-left: 24px;
}

.snackbar > .right {
    float: right;
    padding: 0 24px;
}

.snackbar .link {
    cursor: pointer;
}

.snackbar.hidden {
    bottom: -100px;
}

.snackbar .link, .snackbar > .left {
    opacity: 1;
    transition: opacity 0.6s 0.2s;
}

.snackbar.hidden .link, .snackbar.hidden > .left {
    opacity: 0;
}

/* Settings Page */

#settings-page {
    line-height: 40px;
}

/*#settings-page .page-bottom {*/

/* confirm it is necessary */

/*    padding-top: 18px;*/

/*}*/

#settings-page .theme-picker {
    height: 28px;
    vertical-align: middle;
}

.theme-picker .color-square {
    position: relative;
    width: 18px;
    height: 18px;
    border-radius: 4px;
    margin: 5px;
    opacity: 0.8;
    transition: 0.2s;
}

.theme-picker .color-square:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 18px;
    height: 18px;
    border: 2px solid black;
    border-radius: 4px;
    opacity: 0.1;
    box-sizing: border-box;
    content: '';
    transition: 0.2s;
}

.theme-picker .color-square.active,
.theme-picker .color-square.active:after {
    width: 24px;
    height: 24px;
    border-radius: 6px;
}

.theme-picker .color-square.active { margin: 2px; }

.theme-picker .theme-picker-divisor {
    width: 2px;
    height: 16px;
    margin: 6px;
    background: var(--secondary-color);
    opacity: 0.2;
}

/* Categories Page */

.categories-list {
    font: 18px var(--Secular-One);
    line-height: 40px;
}

.categories-list-item > span:nth-child(1) {
    width: 32px;
    margin-right: 12px;
    text-align: right;
    vertical-align: top;
}

#categories-page .category-title {
    margin: 14px 0;
    font: italic 18px var(--Roboto-Mono);
    text-align: center;
}

/* Centered Div */

.centered-div-outer {
    display: table;
    width: 100%;
    height: 100%;
}

.centered-div-middle {
    display: table-cell;
    vertical-align: middle;
}

.centered-div-inner {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

/* MEDIA TAGS */

@media screen and (min-width: 664px) {
    .page-content {
        margin-top: calc(50% - 332px);
    }
}

@media screen and (min-width: 700px) {

    .snackbar {
        width: auto;
        min-width: 288px;
    }
}

@media screen and (min-width: 768px) {
    .page-content {
        margin-top: 52px;
    }
}

/* -- Standard Responsive -- */

/* phone portrait: < 600w (default) */

/* phone landscape: 600w - 800w & < 500h */

/* tablet portrait: 600w - 800w & > 500h */

/* tablet landscape: > 800w */

/* laptop: > 1100w */

/* laptop wide: > 1400w */

@media screen /* p-p */
and (max-width: 600px) {
    /* a corrective.. page titles didn't fit on phone portrait */
    .page-header > .page-title {
        font-size: 36px;
        margin: 8px 0;
    }
    .sign-in-provider-button-separator {
        width: 100%;
        margin: 0 0 8px 0;
    }
}

@media not screen /* !p-p */
and (max-width: 600px) {
    .hymn-number {
        margin-left: calc(50% - 60px);
        text-align: center;
    }

    .hymn-number.long { margin-left: calc(50% - 100px) }

    #hymn-name {
        text-align: center;
    }
}

@media screen /* p-l */
and (min-width: 600px)
and (max-width: 800px)
and (max-height: 500px) {
    .page-content {
        margin-top: 0;
    }
    .home-header {
        margin-top: 0;
        margin-bottom: 24px;
    }
    .named-icon-button {
        width: auto;
        margin: 0 12px;
    }
    .named-icon-button > i {
        vertical-align: top;
        margin-bottom: 0;
    }
    .named-icon-button > span {
        width: auto;
        margin-left: 14px;
    }
}

@media screen /* > t-p */
and (min-width: 600px) {
    .named-icon-button {
        margin: 0 22px;
    }
}

@media screen /* > p-l */
and (min-width: 600px)
and (min-height: 500px) {
    .presentation-content .hymn-lyrics {
        font-size: 24px;
    }
}





/*# sourceMappingURL=index.6e7984ec.css.map */
